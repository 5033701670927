import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLiveCastUrls,
  setLiveCastUrl,
  fetchDailyQuote,
  setDailyQuote,
  fetchAnnouncements,
  addAnnouncement,
  deleteAnnouncement,
  deleteLiveCast,
} from "./services";

export const adminPanelSlice = createSlice({
  name: "adminPanel",
  initialState: {
    items: [],
    liveCasts: [], 
    dailyQuote: "",
    dailyQuoteTimestamp: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Live Cast URL reducers
    builder
      .addCase(fetchLiveCastUrls.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLiveCastUrls.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.liveCasts = action.payload; // Store the array of liveCasts
      })
      .addCase(fetchLiveCastUrls.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Set Live Cast URL reducers
      .addCase(setLiveCastUrl.fulfilled, (state, action) => {
        state.liveCasts.push(action.payload);
      });

    builder
      // Handle live cast deletion
      .addCase(deleteLiveCast.fulfilled, (state, action) => {
        // Remove the deleted live cast from state
        state.liveCasts = state.liveCasts.filter(
          (cast) => cast._id !== action.payload._id
        );
      })
      .addCase(deleteLiveCast.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });

    // Daily Quote reducers
    builder
      .addCase(fetchDailyQuote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDailyQuote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dailyQuote = action.payload.dailyQuote;
        state.dailyQuoteTimestamp = action.payload.dailyQuoteTimestamp;
      })
      .addCase(fetchDailyQuote.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(setDailyQuote.fulfilled, (state, action) => {
        state.dailyQuote = action.payload.dailyQuote;
        state.dailyQuoteTimestamp = action.payload.dailyQuoteTimestamp;
      });

    // Duyuruları fetch etme
    builder
      .addCase(fetchAnnouncements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.announcements;
      })
      .addCase(fetchAnnouncements.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Yeni duyuru ekleme
      .addCase(addAnnouncement.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      // Duyuru silme
      .addCase(deleteAnnouncement.fulfilled, (state, action) => {
        state.items = state.items.filter(
          (announcement) => announcement._id !== action.payload._id
        );
      });
  },
});

export const selectLiveCastUrls = (state) => state.adminPanel.liveCasts;
export const selectDailyQuote = (state) => state.adminPanel.dailyQuote;
export const selectDailyQuoteTimestamp = (state) =>
  state.adminPanel.dailyQuoteTimestamp;

export default adminPanelSlice.reducer;
