// React essantials
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Material UI components
import {
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Material UI icons
import MenuIcon from "@mui/icons-material/Menu";
import UpdateIcon from "@mui/icons-material/Update";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import PlayArrow from "@mui/icons-material/PlayArrow";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ReplayIcon from "@mui/icons-material/Replay";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TrophyProgress from "components/Custom/TrophyProgress";
import AdviceCard from "components/Custom/AdviceCard";
import MDSnackbar from "components/MDSnackbar";
import AlertDialog from "components/Custom/Dialog";

// Redux slices
import { fetchVideos } from "slices/Videos/services";
import { videoComplete, fetchCompletedItems } from "slices/Statistics/services";
import { fetchQuizzes, fetchQuizGroups } from "slices/Quizzes/services";
import { saveItemOrder } from "slices/Common/services";

// Helpers
import { formatDuration, formatDateTime } from "helpers/index";

// Drag and Drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Helper functions
import { findUserId } from "helpers";
import { findUserRole } from "helpers";

// Vimeo
import Player from "@vimeo/player";

export default function index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subject } = useParams();
  const userId = findUserId();
  const userRole = findUserRole();

  const videoRef = useRef(null); // Video elementi için ref
  const playerRef = useRef(null); // Player referansı ekleyin

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const quizGroups = useSelector((state) => state.quizzes.groups || []);
  const videos = useSelector((state) => state.videos.items);
  const completedVideos = useSelector(
    (state) => state.statistics.completedVideos || []
  );
  const completedTests = useSelector(
    (state) => state.statistics.completedTests || []
  );

  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [isLastVideo, setIsLastVideo] = useState(false);
  const [totalDuration, setTotalDuration] = useState("");
  const [showOverlay, setShowOverlay] = useState(false); // Overlay gösterim durumu için state
  const [latestUploadDate, setLatestUploadDate] = useState("");
  const [drawerTop, setDrawerTop] = useState(0);
  const [drawerHeight, setDrawerHeight] = useState(window.innerHeight);
  const [filteredVideos, setFilteredVideos] = useState([]); // filteredVideos state'i ve onu güncelleyecek setter fonksiyonu
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [open, setOpen] = useState(false);
  const [previewMode, setPreviewMode] = useState(false); // State for preview mode
  const [selectedQuiz, setSelectedQuiz] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [items, setItems] = useState([]);

  const completedTest = completedTests.find(
    (completed) => completed.testId.toString() === selectedQuiz._id
  );

  const handleItemClick = (id, type) => {
    setSelectedItemId(id);
    if (type === "video") {
      const videoItem = filteredVideos.find((video) => video._id === id);
      if (videoItem?.subTopic.includes("Testi Soru Çözümü")) {
        // İlgili test adını çıkaralım
        const relatedTestName = videoItem.subTopic.replace(
          " Testi Soru Çözümü",
          ""
        );
        const relatedTest = quizGroups.find(
          (quiz) => quiz.mainTopic === relatedTestName
        );

        if (
          relatedTest &&
          !completedTests.some(
            (completed) => completed.testId === relatedTest._id
          )
        ) {
          // Eğer test tamamlanmadıysa kullanıcıya bir mesaj gösterelim
          openSB(
            "error",
            "warning",
            "Uyarı",
            `Bu videoyu izleyebilmek için önce ${relatedTestName} testini tamamlamanız gerekmektedir.`
          );
          return;
        }
      }
      setSelectedVideoId(id);
      if (showOverlay) {
        setShowOverlay(false);
        clearTimeout(timer);
      }
      const index = filteredVideos.findIndex((video) => video._id === id);
      setVideoIndex(index);
      setIsLastVideo(index === filteredVideos.length - 1); // Check if the selected video is the last one
    } else if (type === "quiz") {
      if (playerRef.current) {
        playerRef.current.pause().catch((error) => {
          console.error("Error pausing the video:", error);
        }); // Video oynuyorsa durdur
      }
      setSelectedQuiz(quizGroups?.find((group) => group._id === id));
      setOpen(true);
    }
  };

  useEffect(() => {
    if (completedTest) {
      setPreviewMode(true);
    }
  }, [completedTest]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCompletedItems(userId));
      dispatch(fetchQuizGroups());
      dispatch(fetchQuizzes());
    } else {
      console.error("User ID not found");
    }
  }, []);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3500);
  };

  useEffect(() => {
    if (videos.length > 0 || quizGroups.length > 0) {
      const filteredVideos = videos
        .filter(
          (video) =>
            video.mainTopic.trim() === subject.trim() && video.isAccessible
        )
        .map((item) => ({
          ...item,
          type: "video",
        }));

      const filteredQuizzes = quizGroups
        .filter((quiz) => quiz.mainTopic.startsWith(subject))
        .map((item) => ({
          ...item,
          type: "quiz",
        }));

      const allItems = [...filteredVideos, ...filteredQuizzes].sort(
        (a, b) => a.order - b.order
      );

      setItems(allItems);

      // İlk öğeyi seçili hale getir
      if (allItems.length > 0) {
        const firstItem = allItems[0];
        setSelectedItemId(firstItem._id);
        if (firstItem.type === "video") {
          setSelectedVideoId(firstItem._id);
        } else if (firstItem.type === "quiz") {
          setSelectedTest(firstItem._id);
        }
      }
    }
  }, [videos, quizGroups, subject]);

  useEffect(() => {
    // Assuming each video has an 'order' property that you want to sort by.
    const sortedAndFilteredVideos = videos
      .filter(
        (video) =>
          video.mainTopic.trim() === subject.trim() && video.isAccessible
      )
      .sort((a, b) => a.order - b.order); // Sorting by the 'order' property

    setFilteredVideos(sortedAndFilteredVideos);
  }, [videos, subject]); // 'videos' or 'subject' change triggers this effect

  useEffect(() => {
    const groupedQuizzes = quizGroups.reduce((acc, quizGroup) => {
      const [quizBaseTopic, subTopic] = quizGroup.mainTopic.split(" - "); // Ana konuyu ve alt konuyu ayır
      if (quizBaseTopic === subject) {
        // Ana konuyu eşleştir
        if (!acc[quizBaseTopic]) {
          acc[quizBaseTopic] = {};
        }
        if (!acc[quizBaseTopic][subTopic]) {
          acc[quizBaseTopic][subTopic] = [];
        }
        acc[quizBaseTopic][subTopic].push(quizGroup); // Quiz grubunu ilgili alt konu altına ekle
      }
      return acc;
    }, {});

    setFilteredQuizzes(groupedQuizzes);
  }, [quizGroups, subject]);

  useEffect(() => {
    if (filteredVideos.length > 0) {
      setSelectedVideoId(filteredVideos[0]._id); // Select the first video by default
      setVideoIndex(0);
      setIsLastVideo(filteredVideos.length === 1);
    }
  }, [filteredVideos]); // Dependency on filteredVideos ensures this runs after videos are set
  const totalVideos = filteredVideos.length;

  // Sadece geçerli konuya ait quiz gruplarını içeren bir array oluşturuyoruz
  const relatedQuizzes = quizGroups.filter((quiz) =>
    quiz.mainTopic.startsWith(subject)
  );
  const totalQuizzes = relatedQuizzes.length;
  const totalItems = totalVideos + totalQuizzes;

  const completedVideosCount = filteredVideos.reduce((count, video) => {
    return (
      count +
      (completedVideos.some((compVideo) => compVideo.videoId === video._id)
        ? 1
        : 0)
    );
  }, 0);

  const completedQuizzesCount = relatedQuizzes.reduce((count, quiz) => {
    return (
      count +
      (completedTests.some((compTest) => compTest.testId === quiz._id) ? 1 : 0)
    );
  }, 0);

  const completedCount = completedVideosCount + completedQuizzesCount;
  const selectedVideo = videos?.filter(
    (video) => video._id === selectedVideoId
  );

  let timer;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNextVideo = () => {
    let nextIndex = videoIndex + 1;
    while (nextIndex < filteredVideos.length) {
      const nextVideo = filteredVideos[nextIndex];
      if (nextVideo?.subTopic.includes("Testi Soru Çözümü")) {
        const relatedTestName = nextVideo.subTopic.replace(
          " Testi Soru Çözümü",
          ""
        );
        const relatedTest = quizGroups.find(
          (quiz) => quiz.mainTopic === relatedTestName
        );
        if (
          relatedTest &&
          !completedTests.some(
            (completed) => completed.testId === relatedTest._id
          )
        ) {
          nextIndex++;
          continue; // İlgili test tamamlanmamışsa sonraki videoya geç
        }
      }
      const nextVideoId = filteredVideos[nextIndex]._id;
      setSelectedVideoId(nextVideoId);
      setSelectedItemId(nextVideoId);
      setVideoIndex(nextIndex);
      setIsLastVideo(nextIndex + 1 === filteredVideos.length);
      setShowOverlay(false);
      return;
    }
    setIsLastVideo(true);
  };

  useEffect(() => {
    dispatch(fetchVideos());
    setVideoIndex(1); // İlk video ile başla
  }, [dispatch]);

  const handleVideoEnd = async () => {
    if (!showOverlay) {
      setShowOverlay(true); // Video ended naturally, show overlay to proceed to next

      if (userId) {
        const videoData = {
          userId: userId,
          videoId: filteredVideos[videoIndex]._id,
        };

        await dispatch(videoComplete(videoData))
          .unwrap()
          .then(() => {
            dispatch(fetchCompletedItems(userId));
          })
          .catch((error) => console.error("Video completion error:", error));
      } else {
        console.error("User ID not found in token");
      }
    }
  };
  useEffect(() => {
    let videoEndedCheckInterval;

    if (filteredVideos.length > 0 && videoRef.current) {
      // Eğer player mevcutsa önce event listener'ı temizle
      if (playerRef.current) {
        playerRef.current.off("ended"); // Eski event dinleyicilerini kaldır
      } else {
        // Player'ı sadece ilk seferde oluştur
        playerRef.current = new Player(videoRef.current, {
          autopause: true,
          autoplay: false,
        });
      }

      // Videoyu yükle ve oynat
      playerRef.current
        .loadVideo(filteredVideos[videoIndex]?.playerEmbedUrl)
        .then(() => {
        })
        .catch((error) => console.error("Video yükleme hatası:", error));

      // 'ended' event'ini dinle
      const handleVideoEndEvent = () => {
        handleVideoEnd();
      };

      playerRef.current.on("ended", handleVideoEndEvent); // Yeni 'ended' event listener'ını ekle

      // Manuel kontrol: Her .5 saniyede bir videonun sona erip ermediğini kontrol et
      videoEndedCheckInterval = setInterval(async () => {
        const ended = await playerRef.current.getEnded();
        if (ended) {
          handleVideoEnd();
          clearInterval(videoEndedCheckInterval); // Video sona erdiğinde intervali temizle
        }
      }, 500); // 3 saniyede bir kontrol

      // Temizlik işlemi: Yeni video yüklendiğinde eski event listener'ı ve intervali kaldır
      return () => {
        if (playerRef.current) {
          playerRef.current.off("ended"); // Event listener'ı temizle
        }
        clearInterval(videoEndedCheckInterval); // Interval'i temizle
      };
    }
  }, [filteredVideos, videoIndex]); // Video veya video listesi değiştiğinde effect çalışsın

  useEffect(() => {
    const totalSeconds = filteredVideos.reduce(
      (acc, curr) => acc + (curr.duration || 0),
      0
    );
    if (filteredVideos.length > 0) {
      const duration = formatDuration(totalSeconds);
      setTotalDuration(duration);
    } else {
      setTotalDuration("0h 0m 0s");
    }

    if (filteredVideos.length > 0) {
      // Extract and sort the upload dates to find the latest
      const latestDate = filteredVideos
        .map((video) => new Date(video.uploadDate))
        .sort((a, b) => b - a)[0]; // Get the most recent date

      // Use your formatDateTime function
      const { readableDate, readableTime } = formatDateTime(latestDate);
      setLatestUploadDate(`${readableDate} - ${readableTime}`);
    }
  }, [filteredVideos]);

  useEffect(() => {
    const handleScroll = () => {
      setDrawerTop(window.scrollY);
      setDrawerHeight(window.innerHeight + window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    // Yeni sırayı içeren obje listesi
    const updatedOrder = newItems.map((item, index) => ({
      _id: item._id,
      type: item.type,
      order: index,
    }));

    try {
      const res = await dispatch(saveItemOrder(updatedOrder));
      if (res.type === "common/saveItemOrder/fulfilled") {
        openSB(
          "success",
          "update",
          "Başarılı",
          "Sıralama başarıyla güncellendi!"
        ); // Show success notification

        // Redux store'daki items'ı güncelle
        setItems(newItems);
      } else if (res.type === "common/saveItemOrder/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          "Sıralama işlemi sırasında hata!"
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  const list = () => (
    <DragDropContext onDragEnd={userRole !== "student" ? onDragEnd : undefined}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) =>
              userRole !== "student" ? (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => handleItemClick(item._id, item.type)}
                        selected={selectedItemId === item._id}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: "#ced4da",
                            color: "common.white",
                            "&:hover": {
                              bgcolor: "#ced4da",
                            },
                          },
                          "&:hover": {
                            bgcolor:
                              selectedItemId === item._id
                                ? "primary.dark"
                                : "#ced4da",
                          },
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        <MDBox display="flex" flexDirection="column">
                          <MDTypography
                            variant="button"
                            color="dark"
                            fontWeight="regular"
                            sx={{ display: "flex", alignItems: "center" }}
                            mb={0.5}
                          >
                            <Checkbox
                              checked={
                                item.type === "video"
                                  ? completedVideos.some(
                                      (compVideo) =>
                                        compVideo.videoId === item._id
                                    )
                                  : completedTests.some(
                                      (compTest) => compTest.testId === item._id
                                    )
                              }
                              color="dark"
                            />
                            <span
                              style={{
                                display: "inline-block",
                                minWidth: "1.5rem",
                              }}
                            >
                              {index + 1}. &nbsp;
                            </span>
                            {item.type === "video"
                              ? item.subTopic
                              : item.mainTopic}
                          </MDTypography>
                          <MDBox display="flex" alignItems="center" gap={1}>
                            <Icon sx={{ color: "#6c757d", marginLeft: 7.6 }}>
                              {item.type == "video" ? "ondemand_video" : "quiz"}
                            </Icon>
                            <MDTypography variant="caption" color="text">
                              {item.type == "video"
                                ? formatDuration(item.duration)
                                : `${item.questions.length * 2} dak`}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </ListItemButton>
                    </ListItem>
                  )}
                </Draggable>
              ) : (
                <ListItem key={item._id} disablePadding>
                  <ListItemButton
                    onClick={() => handleItemClick(item._id, item.type)}
                    selected={selectedItemId === item._id}
                    sx={{
                      "&.Mui-selected": {
                        bgcolor: "#ced4da",
                        color: "common.white",
                        "&:hover": {
                          bgcolor: "#ced4da",
                        },
                      },
                      "&:hover": {
                        bgcolor:
                          selectedItemId === item._id
                            ? "primary.dark"
                            : "#ced4da",
                      },
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      display: "flex",
                    }}
                  >
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="button"
                        color="dark"
                        fontWeight="regular"
                        sx={{ display: "flex", alignItems: "center" }}
                        mb={0.5}
                      >
                        <Checkbox
                          checked={
                            item.type === "video"
                              ? completedVideos.some(
                                  (compVideo) => compVideo.videoId === item._id
                                )
                              : completedTests.some(
                                  (compTest) => compTest.testId === item._id
                                )
                          }
                          color="dark"
                        />
                        <span
                          style={{
                            display: "inline-block",
                            minWidth: "1.5rem",
                          }}
                        >
                          {index + 1}. &nbsp;
                        </span>
                        {item.type === "video" ? item.subTopic : item.mainTopic}
                      </MDTypography>
                      <MDBox display="flex" alignItems="center" gap={1}>
                        <Icon sx={{ color: "#6c757d", marginLeft: 7.6 }}>
                          {item.type == "video" ? "ondemand_video" : "quiz"}
                        </Icon>
                        <MDTypography variant="caption" color="text">
                          {item.type == "video"
                            ? formatDuration(item.duration)
                            : `${item.questions.length * 2} dak`}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </ListItemButton>
                </ListItem>
              )
            )}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );

  const VideoOverlay = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      if (showOverlay && !isLastVideo) {
        let nextIndex = videoIndex + 1;
        while (nextIndex < filteredVideos.length) {
          const nextVideo = filteredVideos[nextIndex];
          if (nextVideo?.subTopic.includes("Testi Soru Çözümü")) {
            const relatedTestName = nextVideo.subTopic.replace(
              " Testi Soru Çözümü",
              ""
            );
            const relatedTest = quizGroups.find(
              (quiz) => quiz.mainTopic === relatedTestName
            );
            if (
              relatedTest &&
              !completedTests.some(
                (completed) => completed.testId === relatedTest._id
              )
            ) {
              setVideoIndex(nextIndex++);
              continue; // İlgili test tamamlanmamışsa sonraki videoya geç
            }
          }
          break;
        }
        if (nextIndex < filteredVideos.length) {
          timer = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress >= 100) {
                clearInterval(timer);
                handleNextVideo();
                setShowOverlay(false);
                return 100;
              }
              return prevProgress + 2;
            });
          }, 100);
        } else {
          setIsLastVideo(true);
        }
      }
      return () => {
        clearInterval(timer);
        setProgress(0);
      };
    }, [showOverlay, isLastVideo, videoIndex]);

    const handleCancel = () => {
      clearInterval(timer);
      setShowOverlay(false);
      setProgress(0);
    };

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor:
            !isLastVideo && !isXsScreen
              ? "rgba(0, 0, 0, 0.8)"
              : !isLastVideo && isXsScreen
              ? "rgba(0, 0, 0, 1)"
              : "#F8F9FA", // Dark overlay
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        {isLastVideo ? (
          <MDBox
            display="flex"
            flexDirection="column"
            textAlign="center"
            alignItems="center"
          >
            <MDTypography
              color={!isLastVideo ? "white" : "dark"}
              variant={isXsScreen ? "h6" : "h5"}
              fontWeight="bold"
              sx={{ margin: 2 }}
            >
              🎉 Tebrikler {subject} konusunu tamamladınız!
            </MDTypography>
            <MDButton
              sx={{ alignSelf: "center" }}
              variant="outlined"
              color="secondary"
              size={isXsScreen ? "small" : "medium"}
              onClick={() => navigate("/lessons")}
            >
              Derslerime Git
            </MDButton>
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
          >
            <MDTypography
              sx={{ color: "#dee2e6" }}
              variant={isXsScreen ? "caption" : "button"}
              mb={1}
            >
              Sıradaki
            </MDTypography>
            <MDTypography
              sx={{ color: "#dee2e6" }}
              variant={isXsScreen ? "h6" : "h4"}
              mb={3}
            >
              {filteredVideos[videoIndex + 1]?.order + 1}.{" "}
              {filteredVideos[videoIndex + 1].subTopic}
            </MDTypography>
            <MDBox position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={progress}
                sx={{ color: "#dee2e6" }}
                size={isXsScreen ? 48 : 68}
              />
              <MDBox
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ color: "secondary.main" }}
              >
                <PlayArrow
                  fontSize={isXsScreen ? "medium" : "large"}
                  color="white"
                  onClick={handleNextVideo}
                  sx={{ cursor: "pointer" }}
                />
              </MDBox>
            </MDBox>
            <MDTypography
              onClick={handleCancel}
              variant={isXsScreen ? "button" : "h6"}
              style={{
                color: "#dee2e6",
                marginTop: "15px",
                cursor: "pointer",
              }}
            >
              İptal
            </MDTypography>
          </MDBox>
        )}
      </div>
    );
  };

  return (
    <MDBox>
      <MDBox
        height="4rem"
        width="100%"
        sx={{ backgroundColor: "#2D2F31" }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <MDBox
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <MDBox
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
            display="flex"
          >
            <MDTypography
              color="light"
              variant={isXsScreen ? "body2" : "h6"}
              fontWeight="bold"
              ml={3}
              mr={2}
              sx={{ letterSpacing: "0.1em" }}
            >
              Dijital Derslik
            </MDTypography>
          </MDBox>
          <Divider orientation="vertical" flexItem light />
          <MDTypography
            color="light"
            variant={isXsScreen ? "button" : "body2"}
            mx={2}
            sx={{ letterSpacing: "0.1em" }}
          >
            {subject}
          </MDTypography>
        </MDBox>

        <MDBox
          mr={!isXsScreen ? 6 : 3}
          mb={!isXsScreen && 1}
          display="flex"
          gap={isXsScreen ? 4 : 5}
        >
          <MDBox>
            <TrophyProgress
              completed={completedCount}
              total={totalItems}
              isXsScreen={isXsScreen}
            />
            {!isXsScreen && (
              <MDTypography
                variant="caption"
                fontWeight="regular"
                color="white"
                ml={5}
              >
                İlerleme Durumunuz
              </MDTypography>
            )}
          </MDBox>
          {isXsScreen && (
            <IconButton
              color="white"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </MDBox>
      </MDBox>
      {selectedVideoId && (
        <MDBox
          sx={{
            position: "relative",
            width: {
              sm: "100%",
              lg: "calc(100% - 300px)",
              xl: "calc(100% - 375px)",
              xxl: "calc(100% - 400px)",
              xxxl: "calc(100% - 500px)",
              "4xl": "calc(100% - 650px)",
              "5xl": "calc(100% - 950px)",
            },
          }}
        >
          <MDBox sx={{ position: "relative" }}>
            {showOverlay && <VideoOverlay />}
            <MDBox
              sx={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingBottom: {
                  xs: "56.25%",
                  "4xl": "46.25%",
                  "5xl": "36.25%",
                },
                backgroundColor: "#2D2F31",
                color: "#fff",
              }}
            >
              <iframe
                ref={videoRef}
                src={filteredVideos[0]?.playerEmbedUrl}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                frameBorder="0"
                allow=" fullscreen; picture-in-picture"
                allowFullScreen
                title="Vimeo Video Player"
              ></iframe>
            </MDBox>
          </MDBox>
          <MDBox px={isXsScreen ? 3 : 5} mt={3}>
            <MDTypography
              px={0.5}
              variant={isXsScreen ? "h5" : "h4"}
              color="dark"
            >
              Genel Bakış
            </MDTypography>
            <Divider />
            <MDBox px={0.5} display="flex">
              <UpdateIcon sx={{ marginTop: 0.5 }} />
              <MDTypography variant="overline" mx={1}>
                Son Güncelleme: {latestUploadDate}
              </MDTypography>
            </MDBox>
            <AdviceCard
              title={"Öğrenim Zamanınızı Planlayın"}
              description={
                "Her gün belirli zamanlarınızı öğrenmeye ayırarak başarıya gidenyolda emin adımlar atın. Araştırmalar, düzenli çalışma programlarınasahip öğrencilerin hedeflerine daha çabuk ulaştığını gösteriyor. Enverimli öğrenme rutinlerinizi belirleyin ve bu alışkanlıklarıpekiştirin."
              }
              icon={<AccessAlarmIcon sx={{ fontSize: 30, color: "#495057" }} />}
              isXsScreen={isXsScreen}
            />

            <Grid container spacing={1} px={0.5} pb={10}>
              <Grid item xs={12} md={3} mt={isXsScreen && 3}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  mt={3}
                >
                  Sayıya göre
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={8} display="flex" flexDirection="column">
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  color="text"
                  mt={!isXsScreen && 3}
                >
                  Dersler: {filteredVideos?.length}
                </MDTypography>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  color="text"
                  mt={1}
                >
                  Video: Toplam {totalDuration}
                </MDTypography>
                {totalQuizzes > 0 && (
                  <MDTypography
                    variant={isXsScreen ? "button" : "body2"}
                    fontWeight="regular"
                    color="text"
                    mt={1}
                  >
                    Deneme Testleri: {totalQuizzes}
                  </MDTypography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={3}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  mt={3}
                >
                  Açıklama
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={8}>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  color="text"
                  mt={!isXsScreen && 3}
                >
                  {selectedVideo[0]?.description}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
      <Drawer
        anchor={"right"}
        variant={isXsScreen ? "temporary" : "permanent"}
        open={isXsScreen ? mobileOpen : true}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            position: "fixed",
            height: "100vh", // Sabit yüksekliği 100vh yapalım
            minHeight: drawerHeight, // Dynamic height
            width: {
              xs: "70%",
              sm: "60%",
              md: "50%",
              lg: "300px",
              xl: "375px",
              xxl: "400px",
              xxxl: "500px",
              "4xl": "650px",
              "5xl": "950px",
            },
            zIndex: "1",
            bgcolor: "#f8f9fa",
            margin: isXsScreen ? 0 : "4rem 0 0 0",
            top: `-${drawerTop}px`, // Dynamic top position
            borderRadius: 0,
            border: ".2px solid #ced4da",
          },
        }}
        //   onClose={toggleDrawer(anchor, false)}
      >
        {list()}
      </Drawer>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
      <AlertDialog
        width="md"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title={
          completedTest ? "Test Önizlemesi" : "Test Kuralları ve Bilgilendirme"
        }
        onSubmit={() => {
          navigate(`/quiz/${selectedQuiz?.mainTopic}`, {
            state: { test: selectedQuiz },
          });
        }}
        actionText={completedTest ? "Teste Git" : "Teste Başla"}
      >
        {completedTest ? (
          <MDBox
            display="flex"
            flexDirection="column"
            textAlign="justify"
            p={1}
          >
            <MDTypography variant="body2" color="text" mb={2}>
              Bu testi daha önce çözdünüz. Önizleme modunda testi
              inceleyebilirsiniz ancak herhangi bir değişiklik yapamazsınız.
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            flexDirection="column"
            textAlign="justify"
            p={1}
          >
            <MDBox display="flex" alignItems="center" mb={1}>
              <AccessTimeIcon sx={{ mr: 2 }} fontSize="medium" />
              <MDTypography variant="body2" color="text">
                Bu test ortalama olarak {selectedQuiz?.questions?.length * 2}{" "}
                dakika sürecek şekilde planlanmıştır, ancak testi tamamlamak
                için belirli bir süre sınırı yoktur.
              </MDTypography>
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={1}>
              <ExitToAppIcon sx={{ mr: 2 }} fontSize="medium" />
              <MDTypography variant="body2" color="text" mt={1}>
                Test ekranından ayrılmamanız önemlidir. Eğer testi bitir demeden
                test ekranını kapatırsanız, cevaplarınız &nbsp;
                <span
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                  kaydedilmeyecektir
                </span>
                .
              </MDTypography>
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={1}>
              <ReplayIcon sx={{ mr: 2 }} fontSize="medium" />
              <MDTypography variant="body2" color="text" mt={1}>
                Testi bitirdikten sonra istediğiniz zaman tekrar teste girerek
                doğru ve yanlış cevaplarınızı görebilirsiniz.
              </MDTypography>
            </MDBox>

            <MDBox display="flex" alignItems="center" mb={1}>
              <ErrorOutlineIcon sx={{ mr: 2 }} fontSize="medium" />
              <MDTypography variant="body2" color="text" mt={1}>
                Soruları bir kez işaretledikten sonra cevaplarınızı &nbsp;
                <span
                  style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                  değiştiremezsiniz
                </span>
                .
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
      </AlertDialog>
    </MDBox>
  );
}
