import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch Live Cast URL
export const fetchLiveCastUrls = createAsyncThunk(
  "adminPanel/fetchLiveCastUrl",
  async () => {
    const response = await axios.get("/data/live-cast-urls");
    return response.data;
  }
);

// Set Live Cast URL
export const setLiveCastUrl = createAsyncThunk(
  "adminPanel/setLiveCastUrl",
  async ({ teacherName, groupName, liveCastUrl }) => {
    const response = await axios.post("/data/live-cast-urls", {
      teacherName,
      groupName,
      liveCastUrl,
    });
    return response.data;
  }
);

export const deleteLiveCast = createAsyncThunk(
  "adminPanel/deleteLiveCast",
  async (id) => {
    const response = await axios.delete(`/data/live-casts/${id}`);
    return response.data;
  }
);

// Fetch Daily Quote
export const fetchDailyQuote = createAsyncThunk(
  "adminPanel/fetchDailyQuote",
  async () => {
    const response = await axios.get("/data/daily-quote");
    return response.data;
  }
);

// Set Daily Quote
export const setDailyQuote = createAsyncThunk(
  "adminPanel/setDailyQuote",
  async (dailyQuote) => {
    const response = await axios.post("/data/daily-quote", { dailyQuote });
    return response.data;
  }
);

// Fetch announcement
export const fetchAnnouncements = createAsyncThunk(
  "announcements/fetchAnnouncements",
  async () => {
    const response = await axios.get("/data/announcements");
    return response.data;
  }
);

// Add announcement
export const addAnnouncement = createAsyncThunk(
  "announcements/addAnnouncement",
  async (announcement) => {
    const response = await axios.post("/data/announcements", announcement);
    return response.data;
  }
);

// Delete announcement
export const deleteAnnouncement = createAsyncThunk(
  "announcements/deleteAnnouncement",
  async (id) => {
    const response = await axios.delete(`/data/announcements/${id}`);
    return response.data;
  }
);
