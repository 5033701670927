import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import { selectLiveCastUrls } from "slices/AdminPanel";

import { Card, Divider, Grid, Icon } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import InfoIcon from "@mui/icons-material/Info";

import { findUserStatus } from "helpers";
import { getTimeDifference } from "helpers";

export default function LiveStreamCard() {
  const liveCasts = useSelector(selectLiveCastUrls); // Now liveCasts is an array
  const [currentTime, setCurrentTime] = useState(new Date());

  const isActive = findUserStatus();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card
      sx={{
        padding: 2,
        overflow: "hidden",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} display="flex" alignItems="center">
          <MDBox
            sx={{
              flex: 1,
              paddingX: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="dark">
              Canlı Yayın
            </MDTypography>
            {liveCasts.length > 1 && (
              <MDBox
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "red",
                  marginLeft: 1,
                  animation: "pulse 1.5s infinite",
                  "@keyframes pulse": {
                    "0%": { transform: "scale(0.9)" },
                    "50%": { transform: "scale(1.2)" },
                    "100%": { transform: "scale(0.9)" },
                  },
                }}
              />
            )}
          </MDBox>
        </Grid>

        {liveCasts.length > 0 ? (
          liveCasts.map((cast, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={0} px={2}>
                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center" my={1}>
                    <MDTypography
                      variant="button"
                      color="text"
                      lineHeight={1}
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      <Icon>person</Icon>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="light"
                      ml={0.5}
                    >
                      {cast.teacherName}
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  {cast.liveCastUrlTimestamp && (
                    <MDBox display="flex" alignItems="center" my={1}>
                      <MDTypography
                        variant="button"
                        color="text"
                        lineHeight={1}
                        sx={{ mt: 0.1, mr: 0.5 }}
                      >
                        <Icon>schedule</Icon>
                      </MDTypography>
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="light"
                        ml={0.5}
                      >
                        {`${getTimeDifference(
                          cast.liveCastUrlTimestamp,
                          currentTime
                        )} başladı`}
                      </MDTypography>
                    </MDBox>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography
                      variant="button"
                      color="text"
                      lineHeight={1}
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      <Icon>groups</Icon>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="light"
                      ml={0.5}
                    >
                      {cast.groupName}
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      color="text"
                    >
                      <LinkIcon
                        color="orange"
                        fontSize="small"
                        sx={{ marginRight: 0.5, marginBottom: 0.2 }}
                      />
                      {isActive ? (
                        <Link
                          to={cast.liveCastUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            background:
                              "linear-gradient(90deg, #FF5F00, #ff8a00)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            textDecoration: "none",
                          }}
                        >
                          Katılmak için tıklayın!
                        </Link>
                      ) : (
                        <MDTypography
                          color="orange"
                          textGradient
                          variant="body2"
                          sx={{ fontSize: 14 }}
                          fontWeight="bold"
                        >
                          Katılmak için aktif üye olmalısınız!
                        </MDTypography>
                      )}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: 0 }} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} display="flex" alignItems="center">
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="bold"
              px={2}
            >
              Şu anda gerçekleşen bir canlı yayın bulunmamaktadır.
            </MDTypography>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          px={2}
          mx={2}
          mt={0.2}
        >
          <MDTypography variant="caption" color="text">
            <MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <InfoIcon />
            </MDTypography>
            Eğer canlı yayın ile ilgili bir hata ya da yanlışlık olduğunu
            düşünüyorsanız, lütfen eğitmeniniz ile iletişime geçiniz.
          </MDTypography>
        </Grid>
      </Grid>
    </Card>
  );
}
