import React, { useEffect, useState } from "react";
import { Card, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAnnouncement } from "slices/AdminPanel/services";
import { addAnnouncement } from "slices/AdminPanel/services";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAnnouncements } from "slices/AdminPanel/services";

export default function AnnouncementManagement({ openSB }) {
  const dispatch = useDispatch();

  const announcements = useSelector((state) => state.adminPanel.items);
  const [newAnnouncement, setNewAnnouncement] = useState({
    title: "",
    description: "",
  });

  const handleAddAnnouncement = async () => {
    if (newAnnouncement.title && newAnnouncement.description) {
      await dispatch(addAnnouncement(newAnnouncement)).then(() => {
        openSB("success", "check", "Başarılı", "Duyuru başarıyla eklendi.");
      });
      setNewAnnouncement({
        title: "",
        description: "",
        color: "info",
        icon: "announcement",
      });
    }
    dispatch(fetchAnnouncements());
  };
  const handleDeleteAnnouncement = async (id) => {
    await dispatch(deleteAnnouncement(id)).then(() => {
      openSB("success", "check", "Başarılı", "Duyuru başarıyla kaldırıldı.");
    });
    dispatch(fetchAnnouncements());
  };

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <Card sx={{ padding: 3 }}>
      <MDBox mb={1}>
        <MDTypography mx={2} variant="h6" fontWeight="medium">
          Duyurular
        </MDTypography>
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          placeholder="Başlık"
          name="title"
          value={newAnnouncement.title}
          onChange={(e) =>
            setNewAnnouncement({
              ...newAnnouncement,
              title: e.target.value.trim(),
            })
          }
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          placeholder="Açıklama"
          name="description"
          value={newAnnouncement.description}
          onChange={(e) =>
            setNewAnnouncement({
              ...newAnnouncement,
              description: e.target.value.trim(),
            })
          }
          fullWidth
        />
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end">
        <MDButton
          onClick={handleAddAnnouncement}
          color="info"
          variant="gradient"
          size="small"
        >
          Duyuru Ekle
        </MDButton>
      </MDBox>

      <MDBox mb={0.5} mt={-3}>
        <MDTypography mx={2} variant="h6" fontWeight="medium">
          Mevcut Duyurular
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox component="ul" p={0} m={0}>
        {announcements.length > 0 ? (
          announcements.map((announcement) => (
            <>
              <MDBox
                key={announcement.id}
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <MDTypography variant="caption" fontWeight="bold" mx={2}>
                    {announcement.title}
                  </MDTypography>
                  <MDTypography variant="caption" mx={2}>
                    {announcement.description}
                  </MDTypography>
                </MDBox>
                <Tooltip title="Kaldır">
                  <IconButton
                    color="error"
                    variant="gradient"
                    onClick={() => handleDeleteAnnouncement(announcement._id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MDBox>
              <Divider />
            </>
          ))
        ) : (
          <MDTypography
            mx={2}
            variant="body2"
            color="text"
            fontWeight="regular"
          >
            Veri bulunamadı.
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}
