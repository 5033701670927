import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Material UI components
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  useMediaQuery,
  Grid,
  Divider,
} from "@mui/material";

// Material UI icons
import TimerIcon from "@mui/icons-material/Timer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { Article } from "@mui/icons-material";

// Custom components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AlertDialog from "components/Custom/Dialog";
import MDSnackbar from "components/MDSnackbar";

// Redux slices
import { testComplete, fetchCompletedItems } from "slices/Statistics/services";

// Helpers
import { findUserId } from "helpers";

// Theme
import colors from "assets/theme/base/colors";
import LoadingPage from "../loading";

const QuizPage = () => {
  const pageRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orange, teal } = colors;

  const { test } = location.state || {};
  const questions = test?.questions;
  const userId = findUserId();

  const completedTests = useSelector(
    (state) => state.statistics.completedTests || []
  );

  const completedTest = completedTests.find(
    (completed) => completed.testId.toString() === test._id.toString()
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    completedTest ? completedTest.answers : Array(questions?.length).fill(null)
  );
  const [openSummary, setOpenSummary] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(
    completedTest ? completedTest.elapsedTime : 0
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [confirmFinishOpen, setConfirmFinishOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [previewMode, setPreviewMode] = useState(!!completedTest);

  const timerRef = useRef(null);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowCorrectAnswer(false);
      if (selectedOptions[currentQuestionIndex + 1] !== null) {
        setShowCorrectAnswer(true);
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowCorrectAnswer(false);
      if (selectedOptions[currentQuestionIndex - 1] !== null) {
        setShowCorrectAnswer(true);
      }
    }
  };

  const handleOptionSelect = (option) => {
    if (previewMode) return; // Prevent selecting options in preview mode
    if (selectedOptions[currentQuestionIndex] === null) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[currentQuestionIndex] = option;
      setSelectedOptions(newSelectedOptions);
    }
  };

  const currentQuestion = questions && questions[currentQuestionIndex];
  const correctAnswer = currentQuestion?.correctAnswer;
  const isAnswerCorrect =
    selectedOptions[currentQuestionIndex] === correctAnswer;

  const allQuestionsAnswered = selectedOptions.every(
    (option) => option !== null
  );

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const correctAnswersCount = selectedOptions?.filter(
    (option, index) => option === questions[index]?.correctAnswer
  ).length;

  const wrongAnswersCount = selectedOptions?.reduce((count, option, index) => {
    if (option !== null && option !== questions[index]?.correctAnswer) {
      return count + 1;
    }
    return count;
  }, 0);

  const emptyAnswersCount = selectedOptions.filter(
    (option) => option === null
  ).length;

  useEffect(() => {
    if (completedTest) {
      setPreviewMode(true);
      setSelectedOptions(completedTest.answers);
      setElapsedTime(completedTest.elapsedTime);
    }
  }, [completedTest]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCompletedItems(userId));
    } else {
      console.error("User ID not found");
    }
  }, []);

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setSelectedOptions(
      completedTest ? completedTest.answers : Array(questions.length).fill(null)
    );
    setShowCorrectAnswer(false);
    setElapsedTime(completedTest ? completedTest.elapsedTime : 0);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (!completedTest) {
      timerRef.current = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [questions?.length, completedTest]);

  useEffect(() => {
    if (allQuestionsAnswered && !completedTest) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [allQuestionsAnswered, completedTest]);

  const getStatus = (index) => {
    if (selectedOptions[index] !== null) {
      return "Cevaplandı";
    } else if (index < currentQuestionIndex) {
      return "Boş bırakıldı";
    } else {
      return "Cevaplanmadı";
    }
  };

  const handleFinishTest = () => {
    setConfirmFinishOpen(true);
  };

  const handleConfirmFinishTest = async () => {
    if (previewMode) {
      navigate(-1);
      return;
    }

    setConfirmFinishOpen(false);

    const testData = {
      userId, // Replace with actual user ID
      testId: test._id, // Replace with actual test ID
      answers: selectedOptions,
      elapsedTime,
    };

    try {
      openSB(
        "info",
        "info",
        "Bildirim",
        <MDTypography variant="button" color="white">
          Test sonuçlarınız kaydediliyor...
        </MDTypography>
      );
      const res = await dispatch(testComplete(testData));
      if (res.type === "statistics/testComplete/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test sonuçlarınız kaydedilirken hata oluştu. Ders sayfasına
            yönlendiriliyorsunuz.
          </MDTypography>
        );
      } else if (res.type === "statistics/testComplete/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test sonuçlarınız başarıyla kaydedildi. Ders sayfasına
            yönlendiriliyorsunuz.
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
        navigate(-1);
      }, 3000);
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        {isSmallScreen && (
          <Grid item xs={12}>
            <MDBox
              px={2}
              py={0.5}
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                onClick={() => setDrawerOpen(!drawerOpen)}
                sx={{
                  paddingY: 1.2,
                }}
              >
                <MenuIcon />
              </IconButton>
              <MDTypography variant="body2" fontWeight="bold">
                {questions[0]?.mainTopic}
              </MDTypography>
            </MDBox>
            <Divider sx={{ margin: 0, padding: 0 }} />
          </Grid>
        )}
        <Grid
          item
          m={2}
          xs={12}
          display="flex"
          alignItems="stretch"
          justifyContent="flex-end"
          gap="10px"
        >
          {!previewMode && (
            <MDBox
              mx={1}
              px={2}
              py={0.5}
              sx={{
                backgroundColor: teal.state,
                borderRadius: "12px",
              }}
              display="flex"
              alignItems="center"
            >
              <TimerIcon color="white" sx={{ marginBottom: 0.2 }} />
              <MDTypography ml={1} variant="body1" color="white">
                {formatTime(elapsedTime)}
              </MDTypography>
            </MDBox>
          )}
          {previewMode && (
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => setOpenSummary(true)}
              size={isXsScreen ? "small" : "medium"}
            >
              Özeti Görüntüle
            </MDButton>
          )}
          <MDButton
            variant="gradient"
            color="error"
            onClick={handleFinishTest}
            size={isXsScreen ? "small" : "medium"}
            fullWidth={isXsScreen}
          >
            {previewMode ? "Önizleme Modundan Çık" : "Testi Bitir"}
          </MDButton>
        </Grid>
        <MDBox width={!isSmallScreen && "300px"}>
          <Drawer
            anchor="left"
            variant={isSmallScreen ? "temporary" : "permanent"}
            open={isSmallScreen ? drawerOpen : true}
            onClose={() => setDrawerOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: isXsScreen ? "60vw" : "300px",
                bgcolor: "#EBEFF4",
                height: "100vh", // Updated drawer height
                boxSizing: "border-box",
                marginTop: 0,
                marginLeft: 0,
                borderRadius: 0,
              },
            }}
          >
            <MDBox
              sx={{
                padding: 2,
                borderBottom: "1px solid #ddd",
                wordWrap: "break-word",
                whiteSpace: "balance",
                overflowWrap: "break-word",
              }}
            >
              <MDTypography
                fontWeight="bold"
                variant={isXsScreen ? "button" : "h6"}
              >
                {questions[0]?.mainTopic}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ overflow: "auto" }}>
              <List>
                {questions.map((_, index) => (
                  <ListItem
                    key={index}
                    onClick={() => {
                      setCurrentQuestionIndex(index);
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemButton
                      selected={index === currentQuestionIndex}
                      sx={{
                        "&.Mui-selected": {
                          bgcolor: "#ced4da",
                          "&:hover": {
                            bgcolor: "#ced4da",
                          },
                        },
                        "&:hover": {
                          bgcolor:
                            index === currentQuestionIndex
                              ? "primary.dark"
                              : "#ced4da",
                        },
                      }}
                    >
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography
                          variant={isXsScreen ? "caption" : "body2"}
                          fontWeight="bold"
                        >{`${index + 1}. Soru`}</MDTypography>
                        <MDTypography
                          variant={isXsScreen ? "caption" : "button"}
                          fontWeight="bold"
                          color="text"
                        >
                          {getStatus(index)}
                        </MDTypography>
                      </MDBox>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </MDBox>
          </Drawer>
        </MDBox>
        <MDBox
          flex={1}
          maxWidth={!isSmallScreen ? "calc(100vw - 300px)" : "100%"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height="100%"
          width="100%"
          sx={{
            padding: isXsScreen ? 2 : 0, // XS ekranlarda padding ekliyoruz
            margin: isXsScreen && 0,
            minHeight: !isXsScreen && "80vh", // Tüm ekranı kaplayacak şekilde kutuyu ortalıyoruz
            marginTop: 0, // Margin'i sıfırlıyoruz
          }}
        >
          <img
            src={currentQuestion?.gcsUrl}
            alt={`Question ${currentQuestionIndex + 1}`}
            style={{
              marginBottom: "1rem",
              objectFit: "contain", // maintain aspect ratio
              maxWidth: "100%", // responsive width
              height: isSmallScreen ? "200px" : "auto", // height for small screens
            }}
          />
          <MDBox sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Grid
              mt={!isXsScreen && 5}
              container
              spacing={2}
              display="flex"
              justifyContent="center"
            >
              {["A", "B", "C", "D"].map((option) => (
                <Grid item xs={6} md={3} key={option}>
                  <MDButton
                    size={isXsScreen ? "medium" : "large"}
                    variant="contained"
                    color={
                      previewMode
                        ? option === correctAnswer
                          ? "success" // Doğru cevap yeşil
                          : option === selectedOptions[currentQuestionIndex]
                          ? "error" // Yanlış seçilen şık kırmızı
                          : "secondary" // Diğer seçenekler gri
                        : selectedOptions[currentQuestionIndex] === option
                        ? "teal" // Test çözme sırasında seçilen şık mavi
                        : "orange" // Diğer seçenekler gri
                    }
                    onClick={() => handleOptionSelect(option)}
                    disabled={
                      selectedOptions[currentQuestionIndex] !== null ||
                      previewMode
                    } // Önizleme modunda ve işaretlendikten sonra seçenekler disabled olacak
                    sx={{ minWidth: 100, width: "100%" }}
                  >
                    {option}
                  </MDButton>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </MDBox>

        <Grid item xs={12}>
          <MDBox
            width="100%"
            display="flex"
            justifyContent="center" // Butonları ortalamak için ayarladık
            alignItems="center"
            position="relative" // Soru numarasını sağa almak için relative positioning
            maxWidth={!isSmallScreen ? "calc(100vw - 300px)" : "100%"}
            sx={{
              position: "fixed",
              bottom: 30,
              right: 0,
              padding: "0.5rem",
              backgroundColor: "#fff",
              zIndex: 1000,
            }}
          >
            {/* İleri ve geri butonlarını ortalıyoruz */}
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDButton
                variant="outlined"
                color="secondary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestionIndex <= 0}
                size={isXsScreen ? "medium" : "large"}
              >
                <ArrowBackIcon />
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleNextQuestion}
                disabled={currentQuestionIndex >= questions.length - 1}
                size={isXsScreen ? "medium" : "large"}
                sx={{ marginLeft: "1rem" }} // Butonlar arasında biraz boşluk bırakıyoruz
              >
                <ArrowForwardIcon />
              </MDButton>
            </MDBox>

            {/* Soru numarasını sağa hizalıyoruz */}
            {!isXsScreen && (
              <MDBox
                sx={{
                  position: "absolute", // Mutlak pozisyon ile sağa sabitliyoruz
                  right: "1rem", // Sağ kenara hizalıyoruz
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDBox
                  px={1}
                  py={0.5}
                  sx={{
                    backgroundColor: "#EBEFF4",
                    borderRadius: "12px",
                  }}
                  display="flex"
                  justifyContent="center"
                  minWidth="5rem"
                >
                  <MDTypography color="dark" variant="body1" fontWeight="bold">
                    {currentQuestionIndex + 1} / {questions.length}
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </Grid>
      </Grid>

      <AlertDialog
        width="sm"
        open={openSummary}
        title="Test Özeti"
        onClose={() => {
          setOpenSummary(false);
        }}
        hasNoAction
        fullScreen={isXsScreen}
      >
        <Grid container spacing={3} p={1} pb={3}>
          <Grid item xs={6} md={6} display="flex" alignItems="center">
            <CheckCircleIcon color="success" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              fontWeight="bold"
            >
              Doğru Sayısı:
            </MDTypography>
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              fontWeight="regular"
              ml={1}
            >
              {correctAnswersCount}
            </MDTypography>
          </Grid>
          <Grid item xs={6} md={6} display="flex" alignItems="center">
            <CancelIcon color="error" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              fontWeight="bold"
            >
              Yanlış Sayısı:
            </MDTypography>
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              fontWeight="regular"
              ml={1}
            >
              {wrongAnswersCount}
            </MDTypography>
          </Grid>
          <Grid item xs={6} md={6} display="flex" alignItems="center">
            <PanoramaFishEyeIcon color="text" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              fontWeight="bold"
            >
              Boş Bırakılan:
            </MDTypography>
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              fontWeight="regular"
              ml={1}
            >
              {emptyAnswersCount}
            </MDTypography>
          </Grid>
          <Grid item xs={6} md={6} display="flex" alignItems="center">
            <AccessTimeIcon color="dark" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              fontWeight="bold"
            >
              Geçen Süre:
            </MDTypography>
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              fontWeight="regular"
              ml={1}
            >
              {formatTime(elapsedTime)}
            </MDTypography>
          </Grid>
          <Grid item xs={12} mt={3} display="flex" alignItems="center">
            <Article color="info" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              fontWeight="bold"
            >
              Cevap Anahtarı
            </MDTypography>
          </Grid>
          {questions.map((question, index) => {
            return (
              <Grid
                item
                xs={4}
                md={3}
                key={index}
                display="flex"
                alignItems="center"
                mt={1}
              >
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  ml={1}
                  color="dark"
                  fontWeight="bold"
                >
                  {index + 1}. Soru:
                </MDTypography>
                <MDTypography
                  variant={isXsScreen ? "button" : "body2"}
                  fontWeight="regular"
                  ml={1}
                  color={
                    question.correctAnswer === selectedOptions[index]
                      ? "success"
                      : selectedOptions[index] === null
                      ? "text"
                      : "warning"
                  }
                >
                  {question.correctAnswer}
                </MDTypography>
              </Grid>
            );
          })}
          <Grid item xs={12} display="flex" alignItems="center" mt={3}>
            <InfoIcon color="text" fontSize="medium" />
            <MDTypography
              variant={isXsScreen ? "button" : "body2"}
              ml={1}
              color="text"
            >
              Canlı derslerde, yapamadığınız kısımlarla ilgili detaylı geri
              bildirim sağlanacaktır.
            </MDTypography>
          </Grid>
        </Grid>
      </AlertDialog>

      <AlertDialog
        width="sm"
        open={confirmFinishOpen}
        onClose={() => setConfirmFinishOpen(false)}
        onSubmit={handleConfirmFinishTest}
        title={previewMode ? "Önizleme Modundan Çık" : "Testi Bitir"}
      >
        <MDTypography color="text" variant="body2" mx={1}>
          {previewMode
            ? "Önizleme modundan çıkmak istediğinizden emin misiniz?"
            : "Testi bitirmek istediğinizden emin misiniz?"}
        </MDTypography>
      </AlertDialog>

      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </>
  );
};

export default QuizPage;
