import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";

// @mui material components
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import QuoteCard from "components/Custom/QuoteCard";
import AnnouncementCard from "components/Custom/AnnouncementCard";
import OverlayCard from "components/Custom/OverlayCard";
import UserScoreCard from "components/Custom/UserScoreCard";
import VaraText from "components/Custom/Handwriting";
import LiveStreamCard from "components/Custom/LiveStreamCard";
import CountdownComponent from "components/Custom/Countdown";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Redux actions
import { fetchUsers } from "slices/Users/services";
import { fetchVideos } from "slices/Videos/services";
import { fetchCompletedItems } from "slices/Statistics/services";
import { fetchQuizGroups } from "slices/Quizzes/services";
import { resetCompletedItems } from "slices/Statistics";
import { fetchLiveCastUrls, fetchDailyQuote } from "slices/AdminPanel/services";

// Helper functions
import { findUserId } from "helpers";

// Loading Page
import LoadingPage from "layouts/pages/loading";

import image1 from "assets/images/illustrations/dashboard-2.png";
import image2 from "assets/images/illustrations/dashboard-1.png";

const createStatisticsCard = (color, icon, title, count) => (
  <Grid item xs={6} md={3}>
    <MDBox mb={1} height="100%">
      <ComplexStatisticsCard
        color={color}
        icon={icon}
        title={title}
        count={count}
      />
    </MDBox>
  </Grid>
);

function Dashboard() {
  const dispatch = useDispatch();

  const varaRef = useRef(null);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isExtraSmallScreen = useMediaQuery("(max-width:350px)");

  const [loading, setLoading] = useState(true);

  const dailyQuote = useSelector((state) => state.adminPanel.dailyQuote);

  const users = useSelector((state) => state.users.users) || [];
  const videos = useSelector((state) => state.videos.items);

  const quizGroups = useSelector((state) => state.quizzes.groups || []);

  const completedTests = useSelector(
    (state) => state.statistics.completedTests || []
  );

  const filteredUsers = useMemo(
    () => users.filter((user) => user.isActive && user.role === "student"),
    [users]
  );

  const filteredVideos = useMemo(
    () => videos.filter((video) => video.isAccessible),
    [videos]
  );

  const uniqueTopicsArray = useMemo(() => {
    const uniqueTopics = new Set(
      filteredVideos.map((video) => video.mainTopic)
    );
    return Array.from(uniqueTopics);
  }, [filteredVideos]);

  const userId = findUserId();

  const originalUser = users?.find((user) => user.id === userId);
  const isStudent = originalUser?.role === "student";

  const exampleReportsBarChartData = {
    labels: ["Test-1", "Test-2", "Test-3", "Test-4"],
    datasets: {
      label: "Başarı Oranı",
      data: ["70.00", "85.00", "90.00", "75.00"],
    },
  };

  const reportsBarChartData = useMemo(() => {
    if (originalUser && !originalUser?.isActive) {
      return exampleReportsBarChartData;
    }
    // Filtrelenmiş quizGroups ve completedTests kullanarak labels ve data oluşturulması
    const filteredQuizGroups = quizGroups.filter((group) =>
      completedTests.some((test) => test.testId === group._id)
    );

    const labels = filteredQuizGroups.map((group) => group.mainTopic);
    const data = filteredQuizGroups.map((group) => {
      const completedTest = completedTests.find(
        (test) => test.testId === group._id
      );

      const totalQuestions = group.questions.length;
      const correctAnswersCount = completedTest.answers.filter(
        (answer, index) => answer === group.questions[index]?.correctAnswer
      ).length;

      return (correctAnswersCount / totalQuestions) * 100;
    });

    return {
      labels: labels,
      datasets: {
        label: "Başarı Oranı",
        data: data.map((item) => item.toFixed(2)),
      },
    };
  }, [quizGroups, completedTests]);

  // const exampleReportsLineChartData = {
  //   labels: ["Deneme 1", "Deneme 2", "Deneme 3", "Deneme 4"],
  //   datasets: {
  //     label: "Deneme Başarı Oranı",
  //     data: ["65.00", "75.00", "80.00", "85.00"],
  //   },
  // };

  // const reportsLineChartData = useMemo(() => {
  //   if (originalUser && !originalUser?.isActive) {
  //     return exampleReportsLineChartData;
  //   }

  //   return {
  //     labels: [],
  //     datasets: {
  //       label: "Deneme Başarı Oranı",
  //       data: [],
  //     },
  //   };
  // }, [originalUser]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    Promise.all([
      dispatch(fetchUsers()),
      dispatch(fetchVideos()),
      dispatch(resetCompletedItems()),
      dispatch(fetchCompletedItems(userId)),
      dispatch(fetchQuizGroups()),
      dispatch(fetchLiveCastUrls()),
      dispatch(fetchDailyQuote()),
    ])
      .then(() => setTimeout(() => setLoading(false), 500))
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false);
      });
  }, [dispatch]);

  const dashboardContent = (
    <MDBox
      py={5}
      mt={{ xs: -1, md: 5 }}
      sx={{
        backgroundColor: isXsScreen ? "transparent" : "#f8f9fa",
        padding: isXsScreen ? 0 : 3,
        borderRadius: "1rem",
      }}
    >
      <Grid
        container
        spacing={3}
        display="flex"
        alignItems="stretch"
        height="100%"
      >
        <>
          {createStatisticsCard(
            "orange",
            "group",
            "Kullanıcılar",
            filteredUsers?.length
          )}
          {createStatisticsCard(
            "orange",
            "topic",
            "Konular",
            uniqueTopicsArray?.length
          )}
          {createStatisticsCard(
            "orange",
            "video_library",
            "Videolar",
            filteredVideos?.length
          )}
          {createStatisticsCard(
            "orange",
            "quiz_icon",
            "Quizler",
            quizGroups?.length
          )}
        </>
        <Grid item xs={12} md={6} lg={4} mb={1}>
          <QuoteCard title={"Günün Sözü"} content={dailyQuote} />
        </Grid>
        <Grid item xs={12} md={6} lg={5} mb={1}>
          <LiveStreamCard />
        </Grid>
        <Grid item xs={12} md={6} lg={3} mb={1}>
          <OverlayCard image={image2} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} mb={1}>
          <AnnouncementCard />
        </Grid>{" "}
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          mb={1}
          mt={{ xs: 1, md: 0, lg: 0, xl: 0 }}
        >
          <UserScoreCard users={filteredUsers} />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <OverlayCard image={image1} />
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <MDBox mb={1} height="100%">
            <ReportsBarChart
              color="info"
              title="Deneme Başarı Oranları"
              description={
                originalUser?.isActive
                  ? "Denemelerinizin her biri için başarı oranlarını aşağıda görebilirsiniz."
                  : "Bu veriler yalnızca örnek amaçlıdır ve gerçek sonuçları yansıtmaz."
              }
              chart={reportsBarChartData}
            />
          </MDBox>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1} height="100%">
            <ReportsLineChart
              color="success"
              title="Deneme Başarı Oranları"
              description={
                originalUser?.isActive
                  ? "Her denemenin başarı oranı"
                  : "Her denemenin başarı oranı (Veriler yalnızca örnek teşkil etmektedir.)"
              }
              chart={reportsLineChartData}
            />
          </MDBox>
        </Grid> */}
      </Grid>
    </MDBox>
  );

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <VaraText /> */}
      {!isXsScreen ? (
        <MDBox display="flex" width="100%" justifyContent="center">
          {dashboardContent}
        </MDBox>
      ) : (
        dashboardContent
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
