// React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// MUI
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// Custom components
import ContactCard from "components/Custom/ContactCard";
import AnnouncementManagement from "components/Custom/AnnouncementManagement";
import Header from "components/Custom/Header";
import OverlayCard from "components/Custom/OverlayCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Redux-store
import { fetchContacts } from "slices/Contact/services";
import { deleteContact } from "slices/Contact/services";
import {
  fetchLiveCastUrls,
  setLiveCastUrl,
  deleteLiveCast,
  setDailyQuote,
  fetchDailyQuote,
} from "slices/AdminPanel/services";

// Loading Page
import LoadingPage from "../loading";

// assets
import image from "assets/images/illustrations/settings.png";

export default function ManagementPanel() {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contact.contacts);

  const liveCasts = useSelector((state) => state.adminPanel.liveCasts);
  const dailyQuote = useSelector((state) => state.adminPanel.dailyQuote);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [teacherName, setTeacherName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [newLiveCastUrl, setNewLiveCastUrl] = useState("");
  const [newDailyQuote, setNewDailyQuote] = useState(dailyQuote || "");
  const [liveCastButtonText, setLiveCastButtonText] = useState("Başlat");
  const [isDailyQuoteButtonDisabled, setIsDailyQuoteButtonDisabled] =
    useState(false);
  const [dailyQuoteButtonText, setDailyQuoteButtonText] = useState("Kaydet");
  const [loading, setLoading] = useState(true);

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
    setTimeout(() => {
      closeSB();
    }, 3000);
  };

  useEffect(() => {
    Promise.all([
      dispatch(fetchContacts()),
      dispatch(fetchLiveCastUrls()),
      dispatch(fetchDailyQuote()),
    ])
      .then(() => setTimeout(() => setLoading(false), 500))
      .catch((error) => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (dailyQuote) {
      setNewDailyQuote(dailyQuote);
      setIsDailyQuoteButtonDisabled(true);
      setDailyQuoteButtonText("Kaydedildi");
    }
  }, [dailyQuote]);

  const handleLiveCastSubmit = (e) => {
    e.preventDefault();
    const trimmedUrl = newLiveCastUrl.trim();
    const trimmedGroupName = groupName.trim(); // Trim group name input

    dispatch(
      setLiveCastUrl({
        teacherName,
        groupName: trimmedGroupName,
        liveCastUrl: trimmedUrl,
      })
    )
      .then(async () => {
        setTeacherName("");
        setNewLiveCastUrl("");
        setGroupName("");

        openSB("success", "check", "Başarılı", "Canlı yayın başlatıldı.");
        setLiveCastButtonText("Başlatıldı");

        await dispatch(fetchLiveCastUrls());

        setTimeout(() => {
          setLiveCastButtonText("Başlat");
        }, 500);
      })
      .catch((error) => {
        console.error("Error adding live cast:", error);
      });
  };

  const handleDailyQuoteSubmit = (e) => {
    e.preventDefault();
    const trimmedQuote = newDailyQuote.trim();
    dispatch(setDailyQuote(trimmedQuote));
    setIsDailyQuoteButtonDisabled(true);
    setDailyQuoteButtonText("Kaydedildi");
  };

  const handleEndLiveCast = (cast) => {
    dispatch(deleteLiveCast(cast._id))
      .then(() => {
        openSB(
          "success",
          "check",
          "Başarılı",
          "Canlı yayın başarıyla sonlandırıldı."
        );
      })
      .catch((error) => {
        openSB(
          "error",
          "warning",
          "Hata",
          "Canlı yayın sonlandırılırken bir hata oluştu."
        );
      });
  };

  const handleLiveCastInputChange = (e) => {
    setNewLiveCastUrl(e.target.value);
  };

  const handleGroupNameInputChange = (e) => {
    setGroupName(e.target.value); // Handle group name input
  };

  const handleDailyQuoteInputChange = (e) => {
    setNewDailyQuote(e.target.value);
    setIsDailyQuoteButtonDisabled(false);
    setDailyQuoteButtonText("Kaydet");
  };

  const handleDelete = async (id) => {
    openSB("info", "warning", "Bildirim", "Mesaj siliniyor...");

    try {
      const res = await dispatch(deleteContact(id));
      if (res.type === "contact/deleteContact/fulfilled") {
        openSB(
          "success",
          "check",
          "Başarılı",
          <MDTypography
            variant={isXsScreen ? "caption" : "button"}
            color="white"
          >
            Mesaj başarıyla kaldırıldı.
          </MDTypography>
        );
      } else if (res.type === "contact/deleteContact/rejected") {
        openSB(
          "error",
          "warning",
          "Hata",
          error?.message || "Mesaj silinirken bir hata oluştu."
        );
      }
    } catch (error) {
      openSB(
        "error",
        "warning",
        "Hata",
        <MDTypography variant={isXsScreen ? "caption" : "button"} color="white">
          {error?.hasOwnProperty("message")
            ? error?.message
            : "Mesaj kaldırılırken bir hata meydana geldi."}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3500);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Yönetim Paneli" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              display: "flex",
              marginBottom: 1,
              height: "100%",
            }}
          >
            <MDBox mb={1} mx={1.5}>
              <MDTypography variant="h6" fontWeight="medium">
                Canlı Yayın
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <MDInput
                  type="text"
                  value={teacherName}
                  onChange={(e) => setTeacherName(e.target.value)}
                  placeholder="Öğretmen İsmi"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  type="text"
                  value={groupName}
                  onChange={handleGroupNameInputChange}
                  placeholder="Grup İsmi"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  type="text"
                  value={newLiveCastUrl}
                  onChange={handleLiveCastInputChange}
                  placeholder="Canlı Yayın URL"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                <MDButton
                  onClick={handleLiveCastSubmit}
                  type="submit"
                  color="info"
                  variant="gradient"
                  size={isXsScreen ? "small" : "small"}
                >
                  {liveCastButtonText}
                </MDButton>
              </Grid>
            </Grid>

            {/* Display live casts */}
            <Grid item xs={12}>
              <MDBox m={1.5} mt={-3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Mevcut Canlı Yayınlar
                </MDTypography>
                <Divider />
                {liveCasts?.length > 0 ? (
                  liveCasts?.map((cast, index) => (
                    <>
                      <Grid
                        key={index}
                        container
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={10}>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            gap={2}
                            mb={0.2}
                          >
                            <MDTypography variant="button" fontWeight="medium">
                              Öğretmen:
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular">
                              {cast?.teacherName}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            gap={2}
                            mb={0.2}
                          >
                            <MDTypography variant="button" fontWeight="medium">
                              Grup İsmi:
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular">
                              {cast?.groupName} {/* Display the group name */}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center" gap={2}>
                            <MDTypography variant="button" fontWeight="medium">
                              Canlı Yayın Linki:
                            </MDTypography>
                            <Tooltip title={cast?.liveCastUrl}>
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                              >
                                {cast?.liveCastUrl?.length >= 25
                                  ? cast?.liveCastUrl?.slice(0, 30)
                                  : cast?.liveCastUrl}
                                ...
                              </MDTypography>
                            </Tooltip>
                          </MDBox>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Tooltip title="Sonlandır">
                            <IconButton
                              color="error"
                              variant="gradient"
                              onClick={() => handleEndLiveCast(cast)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))
                ) : (
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="regular"
                  >
                    Henüz canlı yayın başlatılmadı.
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <OverlayCard image={image} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <MDBox mb={1} mx={1.5}>
              <MDTypography variant="h6" fontWeight="medium">
                Günün Sözü
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDInput
                  type="text"
                  value={newDailyQuote}
                  onChange={handleDailyQuoteInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                <MDButton
                  onClick={handleDailyQuoteSubmit}
                  type="submit"
                  color="info"
                  variant="gradient"
                  size={isXsScreen ? "small" : "small"}
                  disabled={isDailyQuoteButtonDisabled}
                >
                  {dailyQuoteButtonText}
                </MDButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            id="delete-account"
            sx={{
              maxHeight: "50rem",
              overflow: "auto",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <MDBox pt={3} px={3}>
              <MDTypography variant="h6" fontWeight="medium" mx={1.5}>
                İletişim Formu
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
              {contacts?.length !== 0 ? (
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  {contacts.map((contact) => (
                    <ContactCard
                      key={contact.id}
                      contact={contact}
                      handleDelete={handleDelete}
                    />
                  ))}
                </MDBox>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  mx={1.5}
                >
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="regular"
                  >
                    Veri bulunamadı.
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </Grid>

        <Grid item xs={12} mt={1}>
          <AnnouncementManagement openSB={openSB} />
        </Grid>
      </Grid>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
        bgGreen
      />
    </DashboardLayout>
  );
}
