import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AlertDialog from "components/Custom/Dialog";
import Dropzone from "components/Custom/Dropzone";
import DynamicAutocomplete from "components/Custom/Autocomplete";
import MDSnackbar from "components/MDSnackbar";
import Header from "components/Custom/Header";
import FormFieldTitle from "components/Custom/FormFieldTitle";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// MUI components
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// Redux-store
import { fetchVideos } from "slices/Videos/services";
import { uploadQuizzes } from "slices/Quizzes/services";

// Data
import QuizzesDataTable from "./data/QuizzesDataTable";

// Loading page
import LoadingPage from "../loading";

const Quizzes = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const videos = useSelector((state) => state.videos.items);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [learningOutcome, setLearningOutcome] = useState("");
  const [questions, setQuestions] = useState([]);

  const [mainTopicOptions, setMainTopicOptions] = useState([]);
  const [selectedMainTopic, setSelectedMainTopic] = useState("");

  const [snackbarContent, setSnackbarContent] = useState({
    color: "info",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const areQuestionsValid = questions.every(
    (question) => question.correctAnswer && question.learningOutcome
  );

  const disabled =
    !files ||
    files.length === 0 ||
    !selectedMainTopic ||
    !difficulty ||
    !learningOutcome ||
    !areQuestionsValid;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setSelectedMainTopic("");
    setDifficulty("");
    setDescription("");
    setLearningOutcome("");
    setQuestions([]);
  };

  const closeSB = () => setOpenSnackbar(false);
  const openSB = (color, icon, title, content) => {
    setSnackbarContent({
      color,
      icon,
      title,
      content,
      dateTime: new Date().toLocaleString(),
    });

    setOpenSnackbar(true);
  };

  const handleSubmit = useCallback(async () => {
    if (mainTopicOptions.some((option) => option.value === selectedMainTopic)) {
      openSB(
        "error",
        "warning",
        "Bildirim",
        <MDTypography variant="button" color="white">
          Bu konu başlığı zaten mevcut. Lütfen farklı bir konu başlığı seçiniz.
        </MDTypography>
      );
      return;
    }

    handleClose();
    openSB(
      "info",
      "notifications",
      "Bildirim",
      <MDTypography variant="button" color="white">
        Test yükleniyor...
      </MDTypography>
    );

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("images", file);
    });

    const singleItems = [
      { key: "mainTopic", value: selectedMainTopic.trim() },
      { key: "difficulty", value: difficulty },
      { key: "learningOutcome", value: learningOutcome },
      { key: "description", value: description },
    ];

    singleItems.forEach((item) => {
      formData.append(item.key, item.value);
    });

    questions.forEach((question, index) => {
      formData.append(
        `questions[${index}][correctAnswer]`,
        question.correctAnswer
      );
      formData.append(
        `questions[${index}][learningOutcome]`,
        question.learningOutcome
      );
    });

    try {
      const res = await dispatch(uploadQuizzes(formData));
      if (res.type === "quizzes/upload/rejected") {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test yüklenirken hata oluştu
          </MDTypography>
        );
      } else if (res.type === "quizzes/upload/fulfilled") {
        openSB(
          "success",
          "check",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Test başarıyla yüklendi
          </MDTypography>
        );
      }
    } catch (err) {
      openSB(
        "error",
        "error",
        "Bildirim",
        <MDTypography variant="button" color="white">
          {err.message}
        </MDTypography>
      );
    } finally {
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  }, [
    dispatch,
    files,
    selectedMainTopic,
    difficulty,
    learningOutcome,
    description,
    questions,
  ]);

  const handleFilesAdded = (files) => {
    setFiles(files);
    setQuestions(files.map(() => ({ correctAnswer: "", learningOutcome: "" })));
  };

  const handleFilesDelete = (index) => {
    setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setQuestions((currentQuestions) =>
      currentQuestions.filter((_, i) => i !== index)
    );
  };

  const handleQuestionChange = (index, field, value) => {
    setQuestions((currentQuestions) =>
      currentQuestions.map((question, i) =>
        i === index ? { ...question, [field]: value } : question
      )
    );
  };

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  useEffect(() => {
    const lessonVideos = videos.filter((video) => video.videoType === "lesson");
    const mainTopics = lessonVideos.map((video) => video?.mainTopic);
    const uniqueMainTopics = Array.from(new Set(mainTopics));
    setMainTopicOptions(
      uniqueMainTopics.map((topic) => ({ value: topic, label: topic }))
    );
  }, [videos]);

  useEffect(() => {
    dispatch(fetchVideos())
      .then((response) => {
        if (response.type === "videos/fetchVideos/fulfilled") {
          setTimeout(() => setLoading(false), 500);
        } else if (response.type === "videos/fetchVideos/rejected") {
          console.error("Error fetching videos:", response.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Unexpected error:", error);
        setLoading(false);
      });
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Denemeler" buttonText="Deneme Yükle" action={handleOpen} />
      <Card sx={{ width: "100%" }}>
        <QuizzesDataTable openSB={openSB} closeSB={closeSB} />
      </Card>
      <AlertDialog
        width="lg"
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="Deneme Yükle"
        disabled={disabled}
        fullScreen={isXsScreen}
      >
        <Grid container spacing={isXsScreen ? 1 : 3}>
          <Grid item xs={12} md={6}>
            <MDBox>
              <FormFieldTitle>Konu</FormFieldTitle>
              <DynamicAutocomplete
                placeholder="Seçiniz..."
                options={mainTopicOptions}
                value={selectedMainTopic}
                onChange={(newValue) =>
                  setSelectedMainTopic(newValue?.value || newValue)
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox>
              <FormFieldTitle>Zorluk Seviyesi</FormFieldTitle>
              <FormControl fullWidth>
                <Select
                  sx={{ minHeight: "3.2rem" }}
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  <MenuItem value="basit">Basit</MenuItem>
                  <MenuItem value="orta">Orta</MenuItem>
                  <MenuItem value="zor">Zor</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} mb={!isXsScreen && 3}>
            <MDBox>
              <FormFieldTitle>Açıklama</FormFieldTitle>
              <TextField
                fullWidth
                rows={4}
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MDBox>
              <FormFieldTitle>Kazanım</FormFieldTitle>
              <TextField
                fullWidth
                value={learningOutcome}
                onChange={(e) => setLearningOutcome(e.target.value)}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox style={{ overflowX: "auto" }}>
          <FormFieldTitle>Sorular</FormFieldTitle>
          <Dropzone
            onFilesAdded={handleFilesAdded}
            onDelete={handleFilesDelete}
            multiple
            accept={{
              "image/*": [".jpeg", ".png", ".jpg"],
            }}
          />

          {questions.length === 0 && (
            <MDBox display="flex" alignItems="center" m={1}>
              <InfoIcon color="text" sx={{ marginBottom: 0.2 }} />
              <MDTypography variant="caption" color="text" mx={0.5}>
                En fazla 20 adet soru yükleyebilirsiniz.
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        {questions.length !== 0 && (
          <Grid container spacing={3} mt={0.5}>
            {questions.map((question, index) => (
              <Grid item xs={12} md={6}>
                <MDBox key={index} mt={1} mb={1}>
                  <FormFieldTitle>{`Soru ${index + 1}`}</FormFieldTitle>
                  <FormControl fullWidth>
                    <InputLabel sx={{ minHeight: "3rem" }}>
                      Doğru Şık
                    </InputLabel>
                    <Select
                      label="Doğru Şık"
                      sx={{ minHeight: "3rem" }}
                      value={question.correctAnswer}
                      onChange={(e) =>
                        handleQuestionChange(
                          index,
                          "correctAnswer",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </Select>
                  </FormControl>
                  <MDBox mt={2}>
                    <TextField
                      fullWidth
                      label="Kazanım"
                      value={question.learningOutcome}
                      onChange={(e) =>
                        handleQuestionChange(
                          index,
                          "learningOutcome",
                          e.target.value
                        )
                      }
                    />
                  </MDBox>
                </MDBox>
              </Grid>
            ))}
          </Grid>
        )}
      </AlertDialog>
      <MDSnackbar
        {...snackbarContent}
        open={openSnackbar}
        onClose={closeSB}
        close={closeSB}
      />
    </DashboardLayout>
  );
};

export default Quizzes;
