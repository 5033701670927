// React essentials
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// @mui material components
import { Card, CardMedia, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Helpers
import { roleMap } from "helpers/index";
import { formatDateTime } from "helpers/index";
import { getRandomAvatar } from "helpers";

// assets
import coverImageMale from "assets/images/profile/bg-profileCard-5.jpeg";
import coverImageFemale from "assets/images/profile/bg-profileCard-6.jpeg";
import profilePicture from "assets/images/other/default-pp.png";

// js-cookie
import Cookies from "js-cookie";

// Redux actions
import { updateProfilePicture } from "slices/Me/services";
import { useLocation } from "react-router-dom";

const Overlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  opacity: 0,
  transition: "opacity 0.3s",
  cursor: "pointer",
  "&:hover": {
    opacity: 1,
  },
}));

export default function ProfileCard({
  user,
  children,
  handleOpen,
  openSB,
  closeSB,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { readableDate, readableTime } = formatDateTime(user?.registrationDate);
  const [fileInput, setFileInput] = useState(null);
  const [userAvatar, setUserAvatar] = useState();

  const [profileImage, setProfileImage] = useState(
    user?.profileImage || user?.profile_image || profilePicture
  );

  useEffect(() => {
    if (user && user.gender && !user.profile_image && !user.profileImage) {
      const randomAvatar = getRandomAvatar(user.gender);
      setProfileImage(randomAvatar);
    }
  }, [user]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const validExtensions = ["image/jpeg", "image/png", "image/jpg"];

    if (file && validExtensions.includes(file.type)) {
      openSB(
        "info",
        "notifications",
        "Bildirim",
        <MDTypography variant="button" color="white">
          Profil fotoğrafınız güncelleniyor...
        </MDTypography>
      );

      const formData = new FormData();
      formData.append("profileImage", file);

      const token = Cookies.get("token");

      try {
        const res = await dispatch(updateProfilePicture({ formData, token }));

        if (res.type === "me/updateProfilePicture/fulfilled") {
          setProfileImage(res.payload.profile_image); // Update local state with new profile image URL
          openSB(
            "success",
            "check",
            "Bildirim",
            <MDTypography variant="button" color="white">
              Profil fotoğrafınız başarıyla güncellendi
            </MDTypography>
          );
        } else {
          throw new Error("Profile picture update failed");
        }
      } catch (error) {
        openSB(
          "error",
          "warning",
          "Bildirim",
          <MDTypography variant="button" color="white">
            Profil fotoğrafınız güncellenemedi
          </MDTypography>
        );
      } finally {
        setTimeout(() => {
          closeSB();
        }, 3000);
      }
    } else {
      openSB(
        "warning",
        "warning",
        "Uyarı",
        <MDTypography variant="button" color="white">
          Sadece jpg, jpeg ve png formatındaki dosyalar yüklenebilir
        </MDTypography>
      );
      setTimeout(() => {
        closeSB();
      }, 3000);
    }
  };

  const handleOverlayClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Card sx={{ width: "100%", paddingBottom: 3 }}>
      <CardMedia
        component="img"
        height={isXsScreen ? "125" : "250"}
        image={
          user?.gender === "female"
            ? coverImageFemale
            : "male"
            ? coverImageMale
            : coverImageMale
        }
        alt="cover-image"
        sx={{
          filter: "brightness(0.9)",
        }}
      />
      <MDBox
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={-10}
        position="relative"
      >
        <MDBox position="relative" width="fit-content">
          <MDAvatar
            src={profileImage}
            alt="profile-image"
            size={"xxl"}
            shadow="lg"
          />
          {!location.pathname.startsWith("/user-management") && (
            <>
              <Overlay onClick={handleOverlayClick}>
                <AddIcon fontSize="large" />
              </Overlay>
              <input
                type="file"
                style={{ display: "none" }}
                ref={setFileInput}
                onChange={handleFileChange}
              />
            </>
          )}
        </MDBox>
      </MDBox>
      <MDBox
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
      >
        <MDTypography mt={1} variant="body1">
          {user.name}
        </MDTypography>
        <MDTypography mt={1} variant="body2" color="text">
          {roleMap[user.role] || "Unknown Role"}
        </MDTypography>
        <ProfileInfoCard
          handleOpen={handleOpen}
          info={{
            Id: user.id,
            kullanıcıAdı: user.username,
            kullanıcıRolü: roleMap[user.role] || "Unknown Role",
            telefonNo: user.phoneNumber,
            ePosta: user.email,
            konum: user.city,
            cinsiyet:
              user.gender === "male"
                ? "Erkek"
                : user.gender === "female"
                ? "Kız"
                : "Belirtilmemiş",
            kayıtTarihi: `${readableDate} - ${readableTime}`,
            "aktif /Pasif": user.isActive ? "Aktif" : "Pasif",
            puan: user.score,
          }}
          shadow={false}
        />
      </MDBox>
      {children}
    </Card>
  );
}
